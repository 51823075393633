import React, { useRef } from "react";
import { Button } from "reactstrap";
const TestSpeaker = () => {
  const player = useRef(null);
  return (
    <div>
      <p>
        Cliquez sur le bouton pour tester votre appareil audio
      </p>
      <audio ref={player} preload="auto" controls={false}>
        <source src="/assets/audio-test.mp3" type="audio/mpeg" />
        Votre client web ne support pas votre appariel audio, veuillez mettre à jour votre client web ou nous contacter
      </audio>
      <Button
        className="speaker-btn"
        color="primary"
        onClick={() => player.current.play()}
      >
        Tester
      </Button>
    </div>
  );
};

export default TestSpeaker;
