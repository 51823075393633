import { useQuery } from '@apollo/client';
import {
  Cog, ContentShare, lightTheme, LocalVideo, MeetingProvider, MeetingStatus, RemoteVideo, RemoteVideos,
  useFeaturedTileState, useLocalVideo, useMeetingManager, useMeetingStatus, useRemoteVideoTileState
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ThemeProvider } from 'styled-components';
import { QUERY_GET_CHIME_MEETING_JOIN_INFO } from '../../queries/session';
import Options, { ToolTip } from './options';
import SelectDevices from './select-devices';


const ChimeMeeting = () => {
  const { loading, error, data } = useQuery(
    QUERY_GET_CHIME_MEETING_JOIN_INFO,
    {
      skip: false,
      fetchPolicy: 'network-only',
    }
  )

  if(loading) {
    return <i>Loading</i>
  }

  if(error) {
    if(error.message.includes("JWTExpired") || error.message.includes('error.inactive-session')) {
      return <div className="session-expired">La présentation est terminée</div>
    }
    return <i>Error</i>
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <MeetingProvider>
        <Join {...data.get_chime_meeting_join_info} />
      </MeetingProvider>
    </ThemeProvider>
  )
}

const Join = ({ meetingInfo, attendeeInfo }) => {
  const [showSettings, setShowSettings] = useState(true)
  const [interactable, setInteractable] = useState(false)
  const meetingStatus = useMeetingStatus()
  useEffect(() => {
    setInteractable(meetingStatus === MeetingStatus.Succeeded)
  }, [meetingStatus, setInteractable])

  const meetingManager = useMeetingManager()

  useEffect(() => {
    meetingManager.join({ meetingInfo, attendeeInfo })
  }, [meetingManager, meetingInfo, attendeeInfo])

  const { tileId } = useFeaturedTileState();
  const { tiles } = useRemoteVideoTileState();
  const { isVideoEnabled } = useLocalVideo();
  
  return (
    <React.Fragment>
      <SelectDevices open={showSettings} toggle={() => setShowSettings(s => !s)} />
      <Button id="settings-button" className="settings-button" onClick={() => setShowSettings(s => !s)}><Cog /></Button>
      <ToolTip id="settings-button" text="Paramètres" />
      <Options interactable={interactable} />
      <ContentShare />
      {
        interactable ?
        <div id="remote-videos">
          {
            tiles.length === 0
              ? <div className="not-conected"><img src="/assets/logo.svg" alt="no videos shared yet" /></div>
              : <RemoteVideo tileId={tileId || tiles[0]} />
          }
          {
            ((tiles.length > 0) || isVideoEnabled) &&
            <div className="mini-videos">
              <Button className="car-arrow prev-arrow"><img src="/assets/left-arrow.svg" alt="prev" /></Button>
              <LocalVideo />
              <RemoteVideos />
              <Button className="car-arrow next-arrow"><img src="/assets/left-arrow.svg" alt="prev" /></Button>
            </div>
          }
        </div>
        :
        <div className="not-conected"><img src="/assets/logo.svg" alt="logo waiting to connect" /></div>
      }
    </React.Fragment>
  )
}

export default ChimeMeeting
