import { DateTime } from "luxon";
import React from 'react';

interface User {
  sub: string;
  name: string;
  type:string;
}

interface Message {
  message: string;
  created_at: string;
  user: User
}

interface MessageObject {
  message: Message
}

const MessageBubble:React.FC<{message:MessageObject; sub:string}> = ({message, sub}) => {
  const fromTimeStamp = DateTime.fromISO(message.created_at).setLocale("fr");
  const time = fromTimeStamp.toLocaleString(DateTime.TIME_24_SIMPLE);
  return (
    <div className={`message-bubble ${message.user.type} ${sub === message.user.sub ? 'message-right' : 'message-left'}`}>
      <div className="message-bubble-container">
        <div className="bubble">
          <h6>{sub === message.user.sub ? 'Vous' : message.user.name}:</h6>
          <div dangerouslySetInnerHTML={{__html: message.message}}></div>
          <span className="time">{time}</span>
        </div>
      </div>
    </div>
  )
}

export default MessageBubble;