import { DeviceLabels, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import React, { useEffect } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import InputAudioDevices from './input-audio-devices';
import InputVideoDevices from './input-video-devices';
// import OutputAudioDevices from './output-audio-devices';
import TestSpeaker from './test-speaker';

const SelectDevices: React.FC<{ open: boolean; toggle: () => void }> = ({
  open,
  toggle,
}) => {
  const meetingManager = useMeetingManager();
  useEffect(() => {
    meetingManager.invokeDeviceProvider(DeviceLabels.AudioAndVideo)
  }, [meetingManager]);
  return (
    <div>
      <Modal
        isOpen={open}
        size="lg"
        toggle={toggle}
        className="select-device-modal"
        backdrop={false}
      >
        <ModalHeader>
          Paramètres
          {/* <Button onClick={() => toggle()} className="btn-close">
            <img alt="close" width="35" src="assets/close-modal.svg" />
          </Button> */}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <h4>1. Test haut-parleur</h4>
              <TestSpeaker />
            </Col>
            <Col md={4}>
              <h4 className="mobile-margin">2. Test microphone</h4>
              <InputAudioDevices />
              {/* <br /> */}
              {/* <OutputAudioDevices /> */}
            </Col>
            <Col md={4}>
              <h4 className="mobile-margin">3. Test vidéo</h4>
              <InputVideoDevices />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="text-right">
            <Button className="mobile-btn" color="secondary" onClick={toggle}>
              Annuler
            </Button>{' '}
            <Button className="mobile-btn" color="primary" onClick={toggle}>
              Sauvegarder les paramètres
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default SelectDevices
