import { gql } from '@apollo/client'

export const QUERY_SESSION_IS_ACTIVE = gql`
  query SessionById($id: uuid!) {
    session_by_pk(id: $id) {
      id
      active
    }
  }
`

export const REGISTER_GUEST_MUTATION = gql`
  mutation RegisterGuest(
    $admin_secret: uuid
    $name: String!
    $session_id: uuid!
    $tutor_secret: uuid
  ) {
    register_guest(
      admin_secret: $admin_secret
      name: $name
      session_id: $session_id
      tutor_secret: $tutor_secret
    ) {
      jwt
      name
      sub
      type
    }
  }
`

export const SUBSCRIPTION_MESSAGE = gql`
  subscription Message {
    message {
      id
      message
      created_at
      user {
        sub
        name
        type
      }
    }
  }
`

export const MUTATION_SEND_MESSAGE = gql`
  mutation InsertMesage($message: String!) {
    insert_message_one(object: { message: $message }) {
      id
      message
      user {
        name
        type
        sub
      }
    }
  }
`

export const QUERY_GET_CHIME_MEETING_JOIN_INFO = gql`
  query {
    get_chime_meeting_join_info {
      meetingInfo
      attendeeInfo
    }
  }
`

