import React from "react";
import ReactQuill from "react-quill";
import { FieldProps } from "formik";
import { Label } from "reactstrap";
import "./quill.scss";

const RichTextEditor: React.FC<
  FieldProps &  { rClass?: string }
> = ({
  field: { name, value },
  form: { setFieldValue, handleSubmit },
  label,
  required,
  ...props
}) => {
  // const [text, setText] = React.useState<any>(value);
  const handleChange = (value: any) => {
    // setText(value);
    setFieldValue(name, value);
  };
  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    // ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ 'font': [] }],
    // [{ 'align': [] }],

    ["clean"], // remove formatting button
  ];
  return (
    <React.Fragment>
      {label && (
        <Label for={name} className={"label-color"}>
          {label}
          {required && <span className="text-danger">*</span>}
        </Label>
      )}
      <ReactQuill
        className={props.rClass}
        value={value}
        placeholder={props.placeholder}
        modules={{
          toolbar: toolbarOptions,
        }}
        onChange={handleChange}
      />
    </React.Fragment>
  );
};

export default RichTextEditor;
