import { useSelectVideoInputDevice, useVideoInputs } from 'amazon-chime-sdk-component-library-react';
import React from 'react';

const InputVideoDevices = () => {
  const { devices, selectedDevice, selectDeviceError } = useVideoInputs();
  const selectVideoInput = useSelectVideoInputDevice();
  const items = devices.map(device => (
    <option selected={device.deviceId === selectedDevice} value={device.deviceId} key={device.deviceId}>{device.label}</option>
  ));

  return (
    <div>
      <select onChange={ (e) => selectVideoInput(e.target.value)}>
      <option >None</option>
        {items}
      </select>
      {selectDeviceError && (<p>{selectDeviceError.message}</p>)}
    </div>
  );
};

export default InputVideoDevices;