import {
  useMutation, useQuery
} from "@apollo/client";
import React, { useContext, useEffect, useMemo } from 'react';
import Swal from "sweetalert2";
import { AppContext } from '../App';
import Header from '../components/header';
import NotificationWithInput from '../components/notifications/notification-with-input';
import { QUERY_SESSION_IS_ACTIVE, REGISTER_GUEST_MUTATION } from '../queries/session';


const UnauthenticatedContent:React.FC = () => {
  const appContext = useContext(AppContext);

  const urlSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const { data, loading, error } = useQuery(QUERY_SESSION_IS_ACTIVE, { variables: { id: appContext.session_id } });
  const [registerGuest] = useMutation(REGISTER_GUEST_MUTATION, {
    onCompleted: (response) => {
      appContext.setJwt(response.register_guest.jwt);
      appContext.setSub(response.register_guest.sub)
    }
  });

  useEffect(() => {
    if (data && data.session_by_pk) {
      NotificationWithInput({
        title: 'Saisissez votre prénom',
        input: "text",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'Valider',
        inputValidator: async (value: any) => {
          if(value.length === 0) {
            return 'Veuillez entrer le prénom';
          }

          Swal.disableButtons();
          try {
            await registerGuest({
              variables: {
                name: value,
                session_id: appContext.session_id,
                admin_secret: urlSearchParams.get('admin_secret'),
                tutor_secret: urlSearchParams.get('tutor_secret')
              }
            })
          } catch (e) {
            if(e.message === 'error.username-taken') {
              return "Nom d'utilisateur pris Veuillez en utiliser un autre."
            } else {
              return e.message
            }

          }
          Swal.enableButtons();
        },
      })
    }
  }, [appContext.session_id, data, registerGuest, urlSearchParams])

  if (loading) return <div>Loading</div>
  if (error) return <div>Error!</div>

  return (
    <React.Fragment>
      <Header/>
      <div className="ua-content-container">
        {data.session_by_pk
          ?
          <h3>Veuillez patienter...</h3>
          :
          <h3>La présentation est terminée</h3>
        }
      </div>
    </React.Fragment>
  )
}

export default UnauthenticatedContent;