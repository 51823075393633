import Swal from "sweetalert2";

const NotificationWithInput = ({
  input,
  text,
  title,
  icon,
  iconHtml,
  showCancelButton,
  confirmButtonText,
  allowEscapeKey,
  allowOutsideClick,
  cancelButtonText,
  inputValidator,
}: {
  icon?: any;
  text?: string;
  title?: string;
  input?:
    | "number"
    | "text"
    | "email"
    | "password"
    | "tel"
    | "range"
    | "textarea"
    | "select"
    | "radio"
    | "checkbox"
    | "file"
    | "url"
    | undefined;
  showCancelButton?: boolean;
  allowEscapeKey?: boolean;
  allowOutsideClick?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  inputValidator?: any;
  iconHtml?: any;
}) =>
  Swal.fire({
    icon,
    input,
    title,
    text,
    confirmButtonColor: "#FF7D4B",
    cancelButtonColor: "#d33",
    confirmButtonText,
    allowEscapeKey,
    allowOutsideClick,
    cancelButtonText,
    inputValidator,
    showCloseButton: false,
    showCancelButton: false,
    iconHtml,
  });

export default NotificationWithInput;
