import { useAudioInputs, useSelectAudioInputDevice } from 'amazon-chime-sdk-component-library-react';
import React from 'react';

const InputAudioDevices = () => {
  const { devices, selectedDevice, selectDeviceError } = useAudioInputs();
  const selectAudioInput = useSelectAudioInputDevice();
  const items = devices.map(device => (
    <option selected={device.deviceId === selectedDevice} value={device.deviceId} key={device.deviceId}>{device.label}</option>
  ));

  return (
    <div>
      <select onChange={(e) => selectAudioInput(e.target.value)}>{items}</select>
      {selectDeviceError && <p>{selectDeviceError.message}</p>}
    </div>
  );
};

export default InputAudioDevices;