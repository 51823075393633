import React, { useContext } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import { AppContext } from '../App'
import Session from '../components/session'
import { WebSocketLink } from '@apollo/client/link/ws';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

function Authenticated() {
  const appContext = useContext(AppContext);

  const httpLink = new HttpLink({
    uri: `https://${process.env.REACT_APP_BASE_URL}/v1/graphql`,
    headers: {
      Authorization: `Bearer ${appContext.jwt}`
    },
  });

  const wsLink = new WebSocketLink({
    uri: `wss://${process.env.REACT_APP_BASE_URL}/v1/graphql`,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${appContext.jwt}`,
        },
      },
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          keyFields: ['sub']
        }
      }
    }),
  });

  return (
    <ApolloProvider client={client}>
      <Session sub={appContext.sub} />
    </ApolloProvider>
  )
}

export default Authenticated
