import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import UnauthenticatedContent from './unauthenticated-content';

function Unauthenticated() {
  const client = new ApolloClient({
    uri: `https://${process.env.REACT_APP_BASE_URL}/v1/graphql`,
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <UnauthenticatedContent />
    </ApolloProvider>
  )
}

export default Unauthenticated
