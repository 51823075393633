import React, { createContext } from 'react';
import './App.scss'

import Unauthenticated from './containers/unauthenticated';
import Authenticated from './containers/authenticated';

import useLocalStorage from './hooks/use-localstorage';

export const AppContext = createContext<{
  session_id: string,
  jwt: string,
  setJwt: (arg0: string) => void,
  sub: string,
  setSub: (arg0: string) => void
}>({
  session_id: "",
  jwt: "",
  setJwt: () => {},
  sub: "",
  setSub: () => {}
});

function App() {
  const session_id = window.location.pathname.split('/')[1]
  const [jwt, setJwt] = useLocalStorage(`${session_id}_jwt`, "");
  const [sub, setSub] = useLocalStorage(`${session_id}_sub`, "");

  return <AppContext.Provider value={{
    session_id,
    jwt,
    setJwt,
    sub,
    setSub
  }}>
    {
      jwt ? <Authenticated /> : <Unauthenticated />
    }
  </AppContext.Provider>
}

export default App
