import { Resizable } from "re-resizable";
import React, { useState } from 'react';
import { Button } from "reactstrap";
import useWindowDimensions from "../hooks/use-window-dimensions";
import ChimeMeeting from './chime-meeting';
import Header from './header';
import MessageCompose from './message-compose';
import MessageThread from './message-thread';

const Session:React.FC<{sub: string}> = ({sub}) => {
  const { width } = useWindowDimensions();
  const [showMessagesforMobile, setShowMessagesforMobile] = useState(false);

  const CustomHandle = (props) => (
    <div className={"custom-handle"} {...props} />
  );

  const Resize = () => (
    <CustomHandle>
      <img draggable="false" src="/assets/size-slider.svg" alt="Slide" />
    </CustomHandle>
  );

  const sizes =
    width < 992
      ? {
          defaultSize: {
            width: "100%",
            height: "100%",
          },
          etc: {
            maxHeight: "100%",
            minHeight: "100%",
          },
          messageContainer: {
            width: "100%",
            height: "100%",
          },
        }
      : {
          defaultSize: {
            width: "70%",
            height: "100%",
          },
          etc: {
            maxWidth: "70%",
            minWidth: "30%",
            minHeight: "100%",
          },
          messageContainer: {
            width: "100%",
            minWidth: "30%",
            minHeight: "100%",
          },
        };

  return (
    <React.Fragment>
    <Header />
    <div
        className="session-with-slider-container"
        style={{
          display: "flex",
          flexDirection: width < 992 ? "column" : "row",
        }}
      >
        <Resizable
          className="video-container"
          defaultSize={sizes.defaultSize}
          {...sizes.etc}
          enable={{
            top: false,
            right: width < 992 ? false : true, //this  should be true for desktop
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          // @ts-ignore
          handleComponent={{ right: <Resize /> }}
          // @ts-ignore
          handleStyles={{
            right: { right: 0, width: 4, background: "#544c7f" },
          }}
        >
          <ChimeMeeting />
        </Resizable>
        <div
          className={`message-container video-session-message-container ${showMessagesforMobile ? "show-messages" : "hide-messages"}`}
          style={sizes.messageContainer}
        >
          <MessageThread sub={sub} />
          <MessageCompose />
        </div>
        <div className="mobile-message-icon">
          <Button
            onClick={() => setShowMessagesforMobile(!showMessagesforMobile)}
          >
            {showMessagesforMobile ? (
              <i className="fas fa-video"></i>
            ) : (
              <i className="fas fa-comments"></i>
            )}
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Session;