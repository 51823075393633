import { useApolloClient } from '@apollo/client';
import {
  Camera,
  ControlBar,
  ControlBarButton,
  MeetingStatus,
  Microphone,
  Phone,
  ScreenShare,
  useContentShareControls,
  useLocalVideo,
  useMeetingManager,
  useMeetingStatus,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react'
import React, { useEffect, useState } from 'react'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'
import { QUERY_GET_CHIME_MEETING_JOIN_INFO } from '../../queries/session'

export const ToolTip = ({ id, text }) => (
  <UncontrolledPopover
    className="custom-video-tooltip"
    target={`#${id}`}
    trigger="hover"
    placement="top"
    delay={0}
  >
    <PopoverBody>{text}</PopoverBody>
  </UncontrolledPopover>
)

const Options = ({ interactable }) => {
  const apolloClient = useApolloClient()
  const meetingStatus = useMeetingStatus()
  const meetingManager = useMeetingManager()
  const [connecting, setConnecting] = useState(false)
  const [disconnected, setDisconnected] = useState(false)

  const { muted, toggleMute } = useToggleLocalMute()
  const { isVideoEnabled, toggleVideo } = useLocalVideo()
  const { toggleContentShare } = useContentShareControls()

  
  useEffect(() => {
    if (meetingStatus === MeetingStatus.Succeeded) {
      setConnecting(false)
    }
  }, [setConnecting, meetingStatus])

  const microphoneButtonProps = {
    icon: (
      <React.Fragment>
        <Microphone id="mute" width="40px" muted={muted} />
        <ToolTip
          id="mute"
          text={muted ? 'Activer le micro' : 'Désactiver le micro'}
        />
      </React.Fragment>
    ),
    onClick: () => toggleMute(),
    label: 'Mute',
  }

  const cameraButtonProps = {
    icon: (
      <React.Fragment>
        <Camera id="camEnable" width="40px" disabled={!isVideoEnabled} />
        <ToolTip
          id="camEnable"
          text={isVideoEnabled ? 'Désactiver la vidéo' : 'Activer la vidéo'}
        />
      </React.Fragment>
    ),

    onClick: () => toggleVideo(),
    label: 'Camera',
  }

  const hangUpButtonProps = {
    icon: (
      <React.Fragment>
        <Phone
          id="connect"
          width="40px"
          color={meetingStatus === MeetingStatus.Succeeded ? '#f00' : '#0f0'}
          className={
            meetingStatus === MeetingStatus.Succeeded ? '' : 'green-btn'
          }
        />
        <ToolTip
          id="connect"
          text={
            meetingStatus === MeetingStatus.Succeeded
              ? 'Arrêter le cours'
              : 'Rejoindre le cours'
          }
        />
      </React.Fragment>
    ),

    onClick: async () => {
      if (meetingStatus === MeetingStatus.Succeeded) {
        meetingManager.leave()
        setDisconnected(true)
      } else {
        setConnecting(true)
        if (disconnected) {
          const response = await apolloClient.query({
            query: QUERY_GET_CHIME_MEETING_JOIN_INFO,
            fetchPolicy: 'network-only',
          })
          await meetingManager.join(response.data.get_chime_meeting_join_info)
        }
        meetingManager.start()
      }
    },
    label: 'End',
    className: connecting ? 'disable-connect-button' : '',
  }

  const laptopButtonProps = {
    icon: (
      <React.Fragment>
        <ScreenShare id="shareScreen" width="40px" />
        <ToolTip id="shareScreen" text="Partager l'écran" />
      </React.Fragment>
    ),
    onClick: () => toggleContentShare(),
    label: 'Screen',
  }

  return (
    <ControlBar className="control-bar" showLabels={false} layout="bottom">
      {interactable && (
        <>
          <ControlBarButton {...microphoneButtonProps} />
          <ControlBarButton {...cameraButtonProps} />
          <ControlBarButton {...laptopButtonProps} />
        </>
      )}
      <ControlBarButton {...hangUpButtonProps} />
    </ControlBar>
  )
}

export default Options
