import React from 'react'
import { useMutation } from '@apollo/client'
import { Formik, Form, Field } from 'formik'
import { Button, Container, Row, Col } from 'reactstrap'
import { MUTATION_SEND_MESSAGE } from '../queries/session'

import RichTextEditor from './rte'

const MessageCompose: React.FC = () => {
  const [sendMessage, { loading: sending }] = useMutation(MUTATION_SEND_MESSAGE)

  return (
    <div className="message-compose">
      <Container fluid>
        <Row>
          <Col>
            <Formik
              initialValues={{ sendMesssage: '' }}
              onSubmit={async (values, { resetForm }) => {
                if (
                  values.sendMesssage.replace(/(<([^>]+)>)/gi, '').length !== 0
                ) {
                  try {
                    await sendMessage({
                      variables: {
                        message: values.sendMesssage.replace(
                          /<p><br><\/p>$/,
                          ''
                        ),
                      },
                    })
                    resetForm()
                  } catch (e) {
                    console.log('debug: ', e)
                  }
                }
              }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <Field
                      type="text"
                      className="message-input"
                      name="sendMesssage"
                      placeholder="Nouveau message…"
                      component={RichTextEditor}
                    />
                    <Button
                      disabled={
                        sending ||
                        values.sendMesssage.replace(/(<([^>]+)>)/gi, '')
                          .length === 0
                      }
                      type="submit"
                      color="primary"
                      className="send-message-btn"
                    >
                      <img src="/assets/send.svg" alt="Send" />
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MessageCompose
