import React from 'react';
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  Container,
} from "reactstrap";

const Header:React.FC = () => {
  return (
    <Navbar
      id="custom-nav"
      className={`custom-navbar session-active-nav`}
      light
      expand="lg"
    >
    <Container fluid>
      <a className="mr-auto navbar-brand" href="https://izycours.fr/">
        <img alt="logo" src="/assets/logo.svg" />
        <span>
          Réussir sa scolarité, <span>c’est facile !</span>
        </span>
      </a>
      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <a
              className="nav-link"
              href={process.env.REACT_APP_HOMEPAGE}
            >
              FERMER
            </a>
          </NavItem>
        </Nav>
      </Collapse>
    </Container>
    </Navbar>
  )
}

export default Header
