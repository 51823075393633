import { useSubscription } from '@apollo/client';
import { DateTime } from "luxon";
import React from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { SUBSCRIPTION_MESSAGE } from '../queries/session';
import MessageBubble from './message-bubble';

const MessageThread:React.FC<{sub: string}> = ({sub}) => {
  const { data, loading, error } = useSubscription(SUBSCRIPTION_MESSAGE);
  // Need to use WS for subscription to work

  if (loading) return <div>Loading...</div>

  if(error) {
    if(error.message.includes("JWTExpired") || error.message.includes('error.inactive-session')) {
      return <div className="session-expired">La présentation est terminée</div>
    }
    return <i>Error</i>
  }

  return (
    <div className="message-thread">
      <ScrollToBottom className="message-scroll-class">
        { data && data.message.sort((a,b) => DateTime.fromISO(b.created_at) - DateTime.fromISO(a.created_at)).map((message,index) => (
        <MessageBubble key={index} sub={sub} message={message} />
        )) }
      </ScrollToBottom>
    </div>
  )
}

export default MessageThread;